.Brands {
  .brandImage {
      max-width: 120px;
      /* height: 5rem; */
      padding: 0 15px;
      object-fit: cover;
    }
    
    .brandImage:last-of-type {
      padding-left: 0;
    }
    
    .inner {
      position: relative;
      width: 100%;
      overflow: hidden;
      height: 7rem;
      margin-bottom: 2rem;
    }
    
    .wrapper {
      position: absolute;
      display: flex;
    }
    
    section {
      display: flex;
      animation: swipe var(--speed) linear infinite backwards;
    }

    
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}


  
  